<template>
  <div>
    <!-- <Payments ref="betPayments" :game="typeform" :bet-payments="payments" @savePayments="savePayments" /> -->
    <form @submit.prevent="save()">
      <b-row>
        <b-col sm="12" md="6">
          <div class="mb-3">
            <label>Mesa Fisica</label>
            <div>
              <select v-model="typeform.providerId" name="providerId" class="form-select border-0 shadow-lg" :class="{
                'is-invalid': typesubmit && $v.typeform.providerId.$error,
              }" placeholder="Seleccione.." ref="rouletteFisicSelect">
                <option disabled selected>{{ $t('helpers.select') }}...</option>
                <option v-for="(item, index) in gameFisic" :key="index" :value="item"
                  :selected="typeform.providerId == item">
                  {{ item }}
                </option>
              </select>
              <div v-if="typesubmit && $v.typeform.providerId.$error" class="invalid-feedback">
                <span v-if="!$v.typeform.providerId.required">
                  Este campo es requerido.
                </span>
              </div>
            </div>
          </div>
        </b-col>
        <b-col sm="12" md="6">
          <div class="mb-3">
            <label>Nombre</label>
            <vs-input v-model="typeform.name" placeholder="Nombre" border class="shadow-lg" :class="{
              'is-invalid': typesubmit && $v.typeform.name.$error,
            }" />
            <div v-if="typesubmit && $v.typeform.name.$error" class="invalid-feedback">
              <span v-if="!$v.typeform.name.required">
                Este campo es requerido.
              </span>
            </div>
          </div>
        </b-col>
        <b-col sm="12" md="6">
          <div class="mb-3">
            <label>Logo</label>
            <vs-input v-model="typeform.logo" placeholder="Logo" border class="shadow-lg" :class="{
              'is-invalid': typesubmit && $v.typeform.logo.$error,
            }" />
            <div v-if="typesubmit && $v.typeform.logo.$error" class="invalid-feedback">
              <span v-if="!$v.typeform.logo.required">
                Este campo es requerido.
              </span>
            </div>
          </div>
        </b-col>
        <b-col sm="12" md="6">
          <div class="mb-3">
            <label>Imagen de fondo</label>
            <vs-input v-model="typeform.imgBackground" placeholder="url background" border class="shadow-lg" :class="{
              'is-invalid': typesubmit && $v.typeform.imgBackground.$error,
            }" />
            <div v-if="typesubmit && $v.typeform.imgBackground.$error" class="invalid-feedback">
              <span v-if="!$v.typeform.imgBackground.required">
                Este campo es requerido.
              </span>
            </div>
          </div>
        </b-col>
        <b-col sm="12" md="6">
          <div class="mb-3">
            <label>Color de fondo</label>
            <vs-input v-model="typeform.color" placeholder="color background" border class="shadow-lg" :class="{
              'is-invalid': typesubmit && $v.typeform.color.$error,
            }" type="color" />
            <div v-if="typesubmit && $v.typeform.color.$error" class="invalid-feedback">
              <span v-if="!$v.typeform.color.required">
                Este campo es requerido.
              </span>
            </div>
          </div>
        </b-col>
        <b-col sm="12" md="6">
          <div class="mb-3">
            <label>URL de transmision</label>
            <vs-input v-model="typeform.urlTransmision" placeholder="color background" border class="shadow-lg" :class="{
              'is-invalid': typesubmit && $v.typeform.urlTransmision.$error,
            }" />
            <div v-if="typesubmit && $v.typeform.urlTransmision.$error" class="invalid-feedback">
              <span v-if="!$v.typeform.urlTransmision.required">
                Este campo es requerido.
              </span>
            </div>
          </div>
        </b-col>
        <b-col sm="12" md="6">
          <div class="mb-3">
            <label>URL para Launch</label>
            <vs-input v-model="typeform.gameUrl" placeholder="color background" border class="shadow-lg" :class="{
              'is-invalid': typesubmit && $v.typeform.gameUrl.$error,
            }" />
            <div v-if="typesubmit && $v.typeform.gameUrl.$error" class="invalid-feedback">
              <span v-if="!$v.typeform.gameUrl.required">
                Este campo es requerido.
              </span>
            </div>
          </div>
        </b-col>
        <!-- <b-col sm="12" md="6">
          <div class="mb-3">
            <label>Tiempo de apuesta</label>
            <vs-input v-model="typeform.betTime" placeholder="color background" border class="shadow-lg" :class="{
              'is-invalid': typesubmit && $v.typeform.betTime.$error,
            }" />
            <div v-if="typesubmit && $v.typeform.betTime.$error" class="invalid-feedback">
              <span v-if="!$v.typeform.betTime.required">
                Este campo es requerido.
              </span>
            </div>
          </div>
        </b-col> -->
        <!-- <b-col sm="12" md="6">
          <vs-button type="button" @click="modifyPayments">
            PAGOS
          </vs-button>
        </b-col> -->
      </b-row>
      <div class="mb-3 mt-3">
        <div class="d-flex">
          <vs-button>Guardar</vs-button>
          <vs-button @click="$emit('closeModa')" success type="button">
            Cancelar
          </vs-button>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import { required } from "vuelidate/lib/validators";
import { mapActions, mapGetters } from "vuex";
// import Payments from "./Payments.vue";

export default {
  props: {
    typeform: {
      type: Object,
      default: () => ({
        name: '',
        logo: '',
        providerId: 'm1',
        betPays: []
      })
    },
    gameFisic: {
      type: Array,
      default: () => {
        return [
          'm1',
          'm2'
        ];
      },
    },
  },
  validations: {
    typeform: {
      name: {
        required,
      },
      providerId: {
        required,
      },
      logo: {
        required
      },
      imgBackground: {
        required
      },
      color: {
        required
      },
      urlTransmision: {
        required
      },
      gameUrl: {
        required
      },
    },
  },
  data() {
    return {
      typesubmit: false,
      payments: []
    };
  },
  computed: {
    ...mapGetters({
      success: 'blackJack/getSuccess'
    })
  },
  methods: {
    async save() {
      try {
        this.typesubmit = true;
        // stop here if form is invalid
        console.log(this.typeform);
        this.$v.$touch();
        if (this.$v.$invalid)
          return console.log("error");
        if (this.typeform._id)
          return this.updateGame(this.typeform);
        this.createGame(this.typeform);
      }
      catch (error) {
        console.log('ERROR SAVE WHEEL', error);
      }
    },
    async updateGame(doc) {
      try {
        await this.update(doc);
        this.$emit("closeModa");
      }
      catch (error) {
        throw new Error(error);
      }
    },
    async createGame(doc) {
      try {
        await this.create(doc);
        if (!this.success)
          throw new Error('Error creando el juego');
        this.$swal.fire({
          title: 'Creado',
          icon: 'success',
        });
        this.$emit("closeModa");
      }
      catch (error) {
        this.$swal.fire({
          title: 'Error creando el juego',
          icon: 'error',
        });
        console.log(error);
      }
    },
    modifyPayments() {
      const { betPays } = this.typeform;
      const bets = betPays || []
      this.payments = [...bets];
      this.$refs.betPayments.modal = true;
    },
    savePayments() {
      this.typeform.betPays = [...this.payments];
      this.save();
    },
    ...mapActions({
      create: 'blackJack/create',
      update: 'blackJack/update'
    })
  },
  // components: { Payments }
}
</script>