<template>
  <Layout>
    <PageHeader :title="title" moda_title="Datos de la mesa" ref="pageForm" :items="items" :isAdmin="isItForAdmin()">
      <template>
        <PageForm :typeform="typeform" @closeModa="closeModa" />
      </template>
    </PageHeader>
    <AddCrupier ref="addCrupierComponent" :game-uuid="gameUuid" :crupiers="crupiers" @closeModal="closeModa" />
    <CardsEmulator ref="cardsEmulatorComponent" :game="game" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mt-4">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    {{ $t('filter.show') }}&nbsp;
                    <vs-select v-model="perPage" color="dark" class="shadow-lg">
                      <vs-option v-for="(op, index) in pageOptions" :key="index" :label="op" :value="op">{{ op
                      }}</vs-option>
                    </vs-select>
                    &nbsp;{{ $t('filter.entries') }}
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_filter" class="dataTables_filter text-md-end">
                  <label class="d-inline-flex align-items-center">
                    {{ $t('filter.search') }}:
                    <vs-input v-model="filter" type="search" placeholder="Buscar..." primary class="shadow-lg"></vs-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <div class="table-responsive mb-0">
              <b-table :items="tableData" :fields="fields" responsive="sm" :per-page="perPage" :current-page="currentPage"
                :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :filter="filter" :filter-included-fields="filterOn">
                <template #cell(logo)="data">
                  <b-img :src="data.item.logo ? data.item.logo : 'NOIMATOCHARGE'" height="80px" class="logo-img"
                    @error="handleError"></b-img>
                </template>
                <template #cell(actions)="data">
                  <div class="action-btn-container">
                    <b-dropdown variant="link" no-caret class="position-absolute">
                      <template #button-content>
                        <i class="fa fa-fw fa-bars font-size-16" />
                      </template>
                      <div v-if="isItForAdmin()">
                        <b-dropdown-item @click="edit(data.item)">
                          <i class="uil-edit m-2 font-size-18" />
                          <span class="align-middle ml-5">Editar</span>
                        </b-dropdown-item>
                      </div>
                      <div v-if="isItForAdmin()">
                        <b-dropdown-item @click="addCrupier(data.item)">
                          <i class="uil-edit m-2 font-size-18" />
                          <span class="align-middle ml-5">Agregar crupier</span>
                        </b-dropdown-item>
                      </div>
                      <div v-if="isItForAdmin()">
                        <b-dropdown-item @click="removeWheel(data.item.uuid)">
                          <i class="mdi mdi-delete-outline m-2 font-size-18" />
                          <span class="align-middle ml-5">Eliminar</span>
                        </b-dropdown-item>
                      </div>
                      <div>
                        <b-dropdown-item @click="sendCards(data.item)">
                          <i class="mdi mdi-google-controller m-2 font-size-18" />
                          <span class="align-middle ml-5">Enviar cartas</span>
                        </b-dropdown-item>
                      </div>
                    </b-dropdown>
                  </div>
                </template>
              </b-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import { AddCrupier, CardsEmulator, PageForm } from "./components";
import { breadCrumbData } from "@/helpers/breadcrumbs/breadCrumbs";
import { mapGetters, mapActions } from 'vuex'

export default {
  components: {
    Layout,
    PageHeader,
    PageForm,
    AddCrupier,
    CardsEmulator
  },
  data() {
    return {
      title: 'Black Jack',
      items: breadCrumbData.blackJack,
      typeform: {},
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      currentPage: 1,
      sortBy: 'desc',
      sortDesc: true,
      filterOn: ['name'],
      userData: {},
      userRole: "",
      gameUuid: '',
      game: {}
    }
  },
  computed: {
    ...mapGetters({
      fields: 'blackJack/getTableHeaders',
      tableData: 'blackJack/getGames',
      success: 'blackJack/getSuccess',
      crupiers: 'crupiers/getCrupiers'
    })
  },
  methods: {
    edit(item) {
      this.typeform = { ...item, };
      this.$refs.pageForm.modal = true;
    },
    async removeWheel(uuid) {
      try {
        const { isConfirmed } = await this.$swal.fire({ title: 'Estas seguro de desactivar el juego?', showCancelButton: true })

        if (!isConfirmed) return

        await this.remove(uuid);
        if (!this.success)
          throw new Error('Error desactivando el juego');
        this.$swal.fire({
          title: 'Juego desactivado',
          icon: 'success',
        });
      } catch (error) {
        console.log('ERROR REMOVE WHEEL', error);
        this.$swal.fire({
          title: 'Error eliminando el juego',
          icon: 'error',
        });
      }
    },
    async getUserRole() {
      this.userData = this.$store.getters["user/getUserData"];
      this.userRole = this.userData.role ? this.userData.role.name : "";
    },
    isItForAdmin() {
      return ["ADMIN"].includes(this.userRole);
    },
    closeModa() {
      this.$refs.pageForm.modal = false;
      this.typeform = {}
      this.fetchGames();
    },
    handleError(e) {
      e.target.src = "https://via.placeholder.com/150";
    },
    async sendCards(item) {
      this.game = item
      this.$refs.cardsEmulatorComponent.modal = true
    },
    async addCrupier(item) {
      this.gameUuid = item.uuid
      try {
        await this.fetchCrupiers()

        const existCrupier = this.crupiers.find(crupier => crupier.uuid === item.croupier)

        this.$refs.addCrupierComponent.modal = true
        this.$refs.addCrupierComponent.value = existCrupier || ''
      } catch (error) {
        console.log('ERROR ADD CRUPIER', error);
      }
    },
    ...mapActions({
      fetchGames: 'blackJack/fetchGames',
      remove: 'blackJack/remove',
      fetchCrupiers: 'crupiers/fetchCrupiersAdmin'
    })
  },
  mounted() {
    this.getUserRole();
    this.fetchGames();
  }
}
</script>