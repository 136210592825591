var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.save()}}},[_c('b-row',[_c('b-col',{attrs:{"sm":"12","md":"6"}},[_c('div',{staticClass:"mb-3"},[_c('label',[_vm._v("Mesa Fisica")]),_c('div',[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.typeform.providerId),expression:"typeform.providerId"}],ref:"rouletteFisicSelect",staticClass:"form-select border-0 shadow-lg",class:{
              'is-invalid': _vm.typesubmit && _vm.$v.typeform.providerId.$error,
            },attrs:{"name":"providerId","placeholder":"Seleccione.."},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.typeform, "providerId", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"disabled":"","selected":""}},[_vm._v(_vm._s(_vm.$t('helpers.select'))+"...")]),_vm._l((_vm.gameFisic),function(item,index){return _c('option',{key:index,domProps:{"value":item,"selected":_vm.typeform.providerId == item}},[_vm._v(" "+_vm._s(item)+" ")])})],2),(_vm.typesubmit && _vm.$v.typeform.providerId.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.typeform.providerId.required)?_c('span',[_vm._v(" Este campo es requerido. ")]):_vm._e()]):_vm._e()])])]),_c('b-col',{attrs:{"sm":"12","md":"6"}},[_c('div',{staticClass:"mb-3"},[_c('label',[_vm._v("Nombre")]),_c('vs-input',{staticClass:"shadow-lg",class:{
            'is-invalid': _vm.typesubmit && _vm.$v.typeform.name.$error,
          },attrs:{"placeholder":"Nombre","border":""},model:{value:(_vm.typeform.name),callback:function ($$v) {_vm.$set(_vm.typeform, "name", $$v)},expression:"typeform.name"}}),(_vm.typesubmit && _vm.$v.typeform.name.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.typeform.name.required)?_c('span',[_vm._v(" Este campo es requerido. ")]):_vm._e()]):_vm._e()],1)]),_c('b-col',{attrs:{"sm":"12","md":"6"}},[_c('div',{staticClass:"mb-3"},[_c('label',[_vm._v("Logo")]),_c('vs-input',{staticClass:"shadow-lg",class:{
            'is-invalid': _vm.typesubmit && _vm.$v.typeform.logo.$error,
          },attrs:{"placeholder":"Logo","border":""},model:{value:(_vm.typeform.logo),callback:function ($$v) {_vm.$set(_vm.typeform, "logo", $$v)},expression:"typeform.logo"}}),(_vm.typesubmit && _vm.$v.typeform.logo.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.typeform.logo.required)?_c('span',[_vm._v(" Este campo es requerido. ")]):_vm._e()]):_vm._e()],1)]),_c('b-col',{attrs:{"sm":"12","md":"6"}},[_c('div',{staticClass:"mb-3"},[_c('label',[_vm._v("Imagen de fondo")]),_c('vs-input',{staticClass:"shadow-lg",class:{
            'is-invalid': _vm.typesubmit && _vm.$v.typeform.imgBackground.$error,
          },attrs:{"placeholder":"url background","border":""},model:{value:(_vm.typeform.imgBackground),callback:function ($$v) {_vm.$set(_vm.typeform, "imgBackground", $$v)},expression:"typeform.imgBackground"}}),(_vm.typesubmit && _vm.$v.typeform.imgBackground.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.typeform.imgBackground.required)?_c('span',[_vm._v(" Este campo es requerido. ")]):_vm._e()]):_vm._e()],1)]),_c('b-col',{attrs:{"sm":"12","md":"6"}},[_c('div',{staticClass:"mb-3"},[_c('label',[_vm._v("Color de fondo")]),_c('vs-input',{staticClass:"shadow-lg",class:{
            'is-invalid': _vm.typesubmit && _vm.$v.typeform.color.$error,
          },attrs:{"placeholder":"color background","border":"","type":"color"},model:{value:(_vm.typeform.color),callback:function ($$v) {_vm.$set(_vm.typeform, "color", $$v)},expression:"typeform.color"}}),(_vm.typesubmit && _vm.$v.typeform.color.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.typeform.color.required)?_c('span',[_vm._v(" Este campo es requerido. ")]):_vm._e()]):_vm._e()],1)]),_c('b-col',{attrs:{"sm":"12","md":"6"}},[_c('div',{staticClass:"mb-3"},[_c('label',[_vm._v("URL de transmision")]),_c('vs-input',{staticClass:"shadow-lg",class:{
            'is-invalid': _vm.typesubmit && _vm.$v.typeform.urlTransmision.$error,
          },attrs:{"placeholder":"color background","border":""},model:{value:(_vm.typeform.urlTransmision),callback:function ($$v) {_vm.$set(_vm.typeform, "urlTransmision", $$v)},expression:"typeform.urlTransmision"}}),(_vm.typesubmit && _vm.$v.typeform.urlTransmision.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.typeform.urlTransmision.required)?_c('span',[_vm._v(" Este campo es requerido. ")]):_vm._e()]):_vm._e()],1)]),_c('b-col',{attrs:{"sm":"12","md":"6"}},[_c('div',{staticClass:"mb-3"},[_c('label',[_vm._v("URL para Launch")]),_c('vs-input',{staticClass:"shadow-lg",class:{
            'is-invalid': _vm.typesubmit && _vm.$v.typeform.gameUrl.$error,
          },attrs:{"placeholder":"color background","border":""},model:{value:(_vm.typeform.gameUrl),callback:function ($$v) {_vm.$set(_vm.typeform, "gameUrl", $$v)},expression:"typeform.gameUrl"}}),(_vm.typesubmit && _vm.$v.typeform.gameUrl.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.typeform.gameUrl.required)?_c('span',[_vm._v(" Este campo es requerido. ")]):_vm._e()]):_vm._e()],1)])],1),_c('div',{staticClass:"mb-3 mt-3"},[_c('div',{staticClass:"d-flex"},[_c('vs-button',[_vm._v("Guardar")]),_c('vs-button',{attrs:{"success":"","type":"button"},on:{"click":function($event){return _vm.$emit('closeModa')}}},[_vm._v(" Cancelar ")])],1)])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }